.wrapper {
  display: flex;
  gap: 10px;
}

.wrapper :first-child {
  flex: 2;
}

.wrapper :last-child {
  flex: 1;
}