.wrapper {
  overflow: auto;
}

.wrapper table {
  box-sizing: border-box;
  min-width: 395px;
  width: 100%;
}

.wrapper td {
  vertical-align: top;
}

.wrapper td, .wrapper th {
  padding: 5px;
  border: #ddd 1px solid;
}

.row td {
  width: 110px;
}

.headcountCell {
  width: 65px !important;
}