.wrapper {
  overflow: auto;
}

.wrapper table {
  min-width: 640px;
  width: 100%;
}

.wrapper td {
  vertical-align: top;
}

.wrapper td, .wrapper th {
  padding: 5px;
  border: #ddd 1px solid;
}

.photoCell, .dateCell, .priceCell {
  width: 110px;
}